var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"allow",rawName:"v-allow:auth",value:('users.read'),expression:"'users.read'",arg:"auth"}]},[_c('SubHeader',{attrs:{"handleClick":_vm.handleShowModal,"buttonText":_vm.$t('pages.user_details.add_user'),"showBtn":_vm.checkPermission('users.create') &&
        _vm.checkPermission('groups.read') &&
        _vm.checkPermission('role.read')}}),_c('div',{staticClass:"flex relative flex-1 flex-col flex-grow max-h-full max-w-full"},[_c('vue-good-table',{key:_vm.pageData?.total,attrs:{"id":"userList","rows":_vm.getRows,"columns":_vm.columnsList,"isLoading":_vm.loadingState.userList,"pagination-options":{
        enabled: true,
        mode: 'records',
        dropdownAllowAll: false,
        perPage: _vm.perPage,
      },"sort-options":{
        enabled: true,
      }},on:{"on-sort-change":_vm.onSortChange,"on-page-change":function($event){return _vm.onPageChange($event)},"on-per-page-change":function($event){return _vm.onPerPageChange($event)}},scopedSlots:_vm._u([{key:"column-filter",fn:function({ column }){return [(column.filterOptions && column.filterOptions.enabled)?_c('Dropdown',{staticClass:"filter-dropdown",attrs:{"reposition":"","searchable":"","multiple":column.filterOptions.multi || false,"placeholder":_vm.$t('general.dropdown_placeholder'),"options":column.filterOptions.filterDropdownItems,"value":_vm.getSelectedOptions(column),"limit":1,"maxWidth":"100%","width":"100px","minWidth":"100%","config":_vm.checkColumnConfig(column),"loading":column.filterOptions.isLoading,"taggable":false,"preserve-search":true,"close-on-select":false,"clear-on-select":false,"showCheckBox":column.filterOptions.multi || false,"selectedOptions":_vm.selectedOptions[column.label],"isTableLargeDropdown":true},on:{"input":(value) => _vm.updateColumnFilters(column, value),"search":function($event){return _vm.searchFilter(column, $event)}}}):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'user_name')?_c('div',{staticClass:"flex gap-3",class:[props.row.system_default && 'default hover-text']},[_c('router-link',{class:!_vm.checkPermission('users.read')
                ? 'opacity-60 pointer-events-none'
                : 'text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron',attrs:{"to":{
              name: 'User Setting',
              params: { id: props.row.id },
            }}},[_vm._v(_vm._s(props.row.user_name))])],1):(props.column.field === 'role_ids')?_c('div',{staticClass:"flex gap-3"},[_c('div',{staticClass:"flex gap-2"},[_vm._l((props.row.role_ids),function(role){return [_c('div',{key:role.role_id,staticClass:"bg-blue-100 text-primary text-sm shadow truncate px-1 rounded font-normal",attrs:{"title":role.title}},[_vm._v(" "+_vm._s(role.title)+" ")])]})],2)]):(props.column.field === 'group_ids')?_c('div',{staticClass:"flex gap-3"},[_c('div',{staticClass:"flex gap-2"},[_vm._l((props.row.group_ids),function(group){return [_c('div',{key:group.group_id,staticClass:"bg-blue-100 text-primary text-sm shadow truncate px-1 rounded font-normal",attrs:{"title":group.title}},[_vm._v(" "+_vm._s(group.title)+" ")])]})],2)]):_vm._e()]}},{key:"loadingContent",fn:function(){return [_c('div',{staticClass:"w-full py-10 justify-center flex"},[_c('Loader')],1)]},proxy:true},{key:"emptystate",fn:function(){return [(!_vm.loadingState.userList && !_vm.usersList.length)?_c('div',{staticClass:"alert alert-info py-5 my-5"},[_vm._v(" "+_vm._s(_vm.$t('pages.user_details.no_data_found'))+" ")]):_c('div',{staticClass:"py-16"})]},proxy:true}])}),(
        _vm.selectedDropDownItems && Object.keys(_vm.selectedDropDownItems).length
      )?_c('button',{staticClass:"cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute left-2 top-2 z-50",on:{"click":_vm.clearFilters}},[_vm._v(" "+_vm._s(_vm.$t('actions.clear_filters'))+" ")]):_vm._e()],1),_c('AddUserModal',{ref:"adduserModal",attrs:{"user":_vm.userData,"roles":_vm.roles,"groups":_vm.groups,"loadingState":_vm.loadingState},on:{"handleSubmit":function($event){return _vm.addUser($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }