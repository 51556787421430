<template>
  <div>
    <sub-header :customCrumbLists="customBredCrumbs" showOnlyCustomCrumbs />
    <div class="flex relative flex-1 flex-col flex-grow max-h-full max-w-full">
      <!-- @on-sort-change="onSortChange" -->
      <!-- :sort-options="{
          enabled: true,
        }" -->
      <!--  @on-page-change="onPageChange($event)"
        @on-per-page-change="onPerPageChange($event)" -->
      <vue-good-table
        id="pendingApprovalList"
        :rows="getRows"
        :columns="columnsList"
        :isLoading="loadingState.pendingApproval"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          dropdownAllowAll: false,
          perPage: perPage,
        }"
        :key="pageData?.total"
      >
        <!-- <template #column-filter="{ column }">
          <Dropdown
            reposition
            searchable
            :multiple="column.filterOptions.multi || false"
            class="filter-dropdown"
            :placeholder="$t('general.dropdown_placeholder')"
            v-if="column.filterOptions && column.filterOptions.enabled"
            :options="column.filterOptions.filterDropdownItems"
            :value="getSelectedOptions(column)"
            @input="(value) => updateColumnFilters(column, value)"
            @search="searchFilter(column, $event)"
            :limit="1"
            maxWidth="100%"
            width="100px"
            minWidth="100%"
            :config="checkColumnConfig(column)"
            :loading="column.filterOptions.isLoading"
            :taggable="false"
            :preserve-search="true"
            :close-on-select="false"
            :clear-on-select="false"
            :showCheckBox="column.filterOptions.multi || false"
            :selectedOptions="selectedOptions[column.label]"
            :isTableLargeDropdown="true"
          >
          </Dropdown>
        </template> -->
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field === 'roles'" class="flex gap-3">
            <div class="flex gap-2">
              <template>
                <div
                  v-for="(role, indx) in props.row.roles"
                  :key="indx"
                  class="bg-blue-100 text-primary text-sm shadow truncate px-1 rounded font-normal"
                  :title="role.name"
                >
                  {{ role.name }}
                </div>
              </template>
            </div>
          </div>
          <div
            v-else-if="props.column.field === 'groups'"
            class="flex gap-3"
          >
            <div class="flex gap-2">
              <template>
                <div
                  v-for="(group, indx) in props.row.groups"
                  :key="indx"
                  class="bg-blue-100 text-primary text-sm shadow truncate px-1 rounded font-normal"
                  :title="group.name"
                >
                  {{ group.name }}
                </div>
              </template>
            </div>
          </div>
          <div
            v-else-if="props.column.field == 'action'"
            class="flex justify-center cursor-pointer"
          >
            <div class="flex items-center justify-between gap-2">
              <div :class="props?.row?.status === 'REJECTED' ? 'text-gray-500 cursor-not-allowed opacity-50 ' : 'cursor-pointer text-error'" @click="handleDecline(props.row)">
                Decline
              </div>
              <div :class="props?.row?.status === 'REJECTED' ? 'text-gray-500 cursor-not-allowed opacity-50 ' : 'cursor-pointer text-success'" @click="handleAccept(props.row)">
                Accept
              </div>
            </div>
          </div>
          <div v-else>
                    {{ props.row[props.column.field] }}
                </div>
        </template>

        <template #loadingContent>
          <div class="w-full py-10 justify-center flex">
            <Loader />
          </div>
        </template>
        <template #emptystate>
          <div
            class="alert alert-info py-5 my-5"
            v-if="!loadingState.pendingApproval && !pendingApprovalList.length"
          >
            No Data Found
          </div>
          <div v-else class="py-16"></div>
        </template>
      </vue-good-table>
      <!-- <button
        v-if="
          selectedDropDownItems && Object.keys(selectedDropDownItems).length
        "
        class=" cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute left-2 top-2 z-50"
        @click="clearFilters"
      >
        Clear filters
      </button> -->
    </div>

    <AddUserModal
      :user="userData"
      ref="AddUserModal"
      @handleSubmit="approveUser($event)"
      :roles="roles"
      :groups="groups"
      :loadingState="loadingState"
      rolesLabel="Roles"
      groupLabel="Groups"
      modalType="approveUser"
      submitButtonText="Approved"
    />

    <ModalConfirm title="Are you sure?" message="" ref="confirm-popup">
    </ModalConfirm>
  </div>
</template>

<script>
import SubHeader from "@/components/SubHeader";
import axios from "@/axios";
import Loader from "@shared/loader";
// import uniqBy from "lodash"
import AddUserModal from "@shared/components/addUserModal";
const ModalConfirm = () => import("@/components/modal-confirm");
import { EventBus } from "@/main.js";


export default {
  name: "PendingApprovals",
  components: {
    SubHeader,
    Loader,
    AddUserModal,
    ModalConfirm,
  },
  data() {
    return {
      searchParams: null,
      pendingApprovalList: [],

      loadingState: {
        pendingApproval: false,
        roles: false,
        groups: false,
      },
      pageData: null,
      perPage: 10,
      roles: [],
      groups: [],
      userData: {
        username: "",
        email: "",
        roles: [],
        groups: [],
        mobile: "",
        position: "",
      },
      selectedRow: null,
    };
  },
  async mounted() {
    this.fetchRoles();
    this.fetchGroups();
    this.pendingApprovalList = await this.fetchPendingApprovalList();
  },
  computed: {
    customBredCrumbs() {
      return [{ name: "Admin" }, { name: "Pending Approval" }];
    },
    getRows() {
      return this.pendingApprovalList;
    },
    columnsList() {
      return [
        {
          label: this.$t('pages.user_details.email'),
          field: "email",
          filterOptions: {
            enabled: false, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "email",
          },
        },
        {
          label: this.$t('pages.user_details.first_name'),
          field: "first_name",
          filterOptions: {
            enabled: false, // enable filter for this column
            multi: true,
            isLoading: false,
          },
        },
        {
          label: this.$t('pages.user_details.lats_name'),
          field: "last_name",
          filterOptions: {
            enabled: false, // disable filter for this column
          },
        },
        {
          label: this.$t('pages.user_details.role'),
          field: "roles",
          sortable: false,
          width: "100px",
          filterOptions: {
            enabled: false, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "role",
          },
        },
        {
          label: this.$t('pages.user_details.group'),
          field: "groups",
          sortable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "group",
          },
        },
        {
          label: this.$t('pages.user_details.authentication_method'),
          field: "authentication_method",
          filterOptions: {
            enabled: false, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "username",
          },
        },
        {
          label: this.$t('pages.user_details.status'),
          field: "status",
          filterOptions: {
            enabled: false, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "username",
          },
        },
        {
          label: this.$t('pages.user_details.action'),
          field: "action",
          filterOptions: {
            enabled: false, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "username",
          },
        },
      ]
    }
  },
  methods: {
    async approveUser(item) {
      try {
        delete item.position;
        delete item.mobile;
        const userData = {...item, groups: item.groups.map((group) => ({name: group.name, id: group.id})),
        roles: item.roles.map((role) => ({name: role.name, id: role.id}))}
        this.selectedRow = {...this.selectedRow, groups: userData.groups, 
        roles: userData.roles, email: userData.email, user_name: userData.username }
        delete this.selectedRow.vgt_id;
        delete this.selectedRow.originalIndex;
        const res = await axios.post(`user/approve`, this.selectedRow);
        if(res) {
          this.pendingApprovalList = await this.fetchPendingApprovalList();
          this.$refs["AddUserModal"].closeModal();
        }
      } catch (err) {
        console.error("err", err);
        this.$toast.error(err?.response?.data?.detail || "Something went wrong");
        this.$refs["AddUserModal"].closeModal();
      }
      
   
    },
    async fetchRoles() {
      this.loadingState.roles = true;
      try {
        let { data } = await axios.get(`role/tenant/${this.$store.getters.getTenantId}`);
        this.roles = data;
      } catch (error) {
        console.log(error, ">>>>error");
      }
      this.loadingState.roles = false;
    },
    async fetchGroups() {
      this.loadingState.groups = true;
      try {
        let { data } = await axios.get(`group/tenant/${this.$store.getters.getTenantId}`);
        this.groups = data;
      } catch (error) {
        console.log(error, ">>>>error");
      }
      this.loadingState.groups = false;
    },
    async handleDecline(row) {
      if (row?.status === 'REJECTED') return;
      const promise = await this.$refs["confirm-popup"].show({
        title: "Are you sure?",
        message:
          "This user will be rejected, please confirm to continue to reject this user request.",
        buttonText: "Confirm",
      });

      if (promise) {
        try {
          const res = await axios.post(`user/reject/${row.id}`);
          if(res) {
            this.pendingApprovalList = await this.fetchPendingApprovalList();
          }
        } catch(err) {
          this.$toast.error(err?.response?.data?.detail || "Something went wrong");
          this.$refs["confirm-popup"].close();
        }
        
      }
      this.$refs["confirm-popup"].close();
    },
    handleAccept(row) {
      if (row?.status === 'REJECTED') return;
      this.selectedRow = row;
      this.userData.username = row.user_name;
      this.userData.email = row.email;
      this.userData.roles = row.roles.map((role) => ({...role, title: role.name}));
      this.userData.groups = row.groups.map((group) => ({...group, title: group.name}));
      this.$refs["AddUserModal"].showUserModal();
    },
    addUser(userData) {
      console.log("userData", userData);
    },
    async fetchPendingApprovalList() {
      this.loadingState.pendingApproval = true;
      try {
        const response = await axios.get(`user/tenant/pending`);
        this.loadingState.pendingApproval = false;
        let pendingApprovalCount = response.data.filter((el) => el.status === 'PENDING').length;
        EventBus.$emit("updateCountForPendingApproval", pendingApprovalCount);
        return response.data;
      } catch (error) {
        this.loadingState.pendingApproval = false;
        this.$toast.error(
          error?.response?.data?.detail || "Something went wrong"
        );
      }
      
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
.filter-dropdown {
  @apply border-transparent bg-white;
}
</style>
