<!-- Localized -->
<template>
  <div class="" v-allow:auth="'users.read'">
    <SubHeader
      :handleClick="handleShowModal"
      :buttonText="$t('pages.user_details.add_user')"
      :showBtn="
        checkPermission('users.create') &&
          checkPermission('groups.read') &&
          checkPermission('role.read')
      "
    />
    <div class="flex relative flex-1 flex-col flex-grow max-h-full max-w-full">
      <vue-good-table
        id="userList"
        :rows="getRows"
        :columns="columnsList"
        :isLoading="loadingState.userList"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          dropdownAllowAll: false,
          perPage: perPage,
        }"
        @on-sort-change="onSortChange"
        :sort-options="{
          enabled: true,
        }"
        :key="pageData?.total"
        @on-page-change="onPageChange($event)"
        @on-per-page-change="onPerPageChange($event)"
      >
        <template #column-filter="{ column }">
          <Dropdown
            reposition
            searchable
            :multiple="column.filterOptions.multi || false"
            class="filter-dropdown"
            :placeholder="$t('general.dropdown_placeholder')"
            v-if="column.filterOptions && column.filterOptions.enabled"
            :options="column.filterOptions.filterDropdownItems"
            :value="getSelectedOptions(column)"
            @input="(value) => updateColumnFilters(column, value)"
            @search="searchFilter(column, $event)"
            :limit="1"
            maxWidth="100%"
            width="100px"
            minWidth="100%"
            :config="checkColumnConfig(column)"
            :loading="column.filterOptions.isLoading"
            :taggable="false"
            :preserve-search="true"
            :close-on-select="false"
            :clear-on-select="false"
            :showCheckBox="column.filterOptions.multi || false"
            :selectedOptions="selectedOptions[column.label]"
            :isTableLargeDropdown="true"
          >
          </Dropdown>
        </template>
        <template slot="table-row" slot-scope="props">
          <div
            v-if="props.column.field === 'user_name'"
            :class="[props.row.system_default && 'default hover-text']"
            class="flex gap-3"
          >
            <router-link
              :to="{
                name: 'User Setting',
                params: { id: props.row.id },
              }"
              :class="
                !checkPermission('users.read')
                  ? 'opacity-60 pointer-events-none'
                  : 'text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron'
              "
              >{{ props.row.user_name }}</router-link
            >
          </div>
          <div v-else-if="props.column.field === 'role_ids'" class="flex gap-3">
            <div class="flex gap-2">
              <template  v-for="(role) in props.row.role_ids" >
                <div
                :key="role.role_id"
                  class="bg-blue-100 text-primary text-sm shadow truncate px-1 rounded font-normal"
                  :title="role.title"
                >
                  {{ role.title }}
                </div>
              </template>
            </div>
          </div>
          <div
            v-else-if="props.column.field === 'group_ids'"
            class="flex gap-3"
          >
            <div class="flex gap-2">
              <template v-for="(group) in props.row.group_ids">
                <div
                  :key="group.group_id"
                  class="bg-blue-100 text-primary text-sm shadow truncate px-1 rounded font-normal"
                  :title="group.title"
                >
                  {{ group.title }}
                </div>
              </template>
            </div>
          </div>
        </template>
        <template #loadingContent>
          <div class="w-full py-10 justify-center flex">
            <Loader />
          </div>
        </template>
        <template #emptystate>
          <div
            class="alert alert-info py-5 my-5"
            v-if="!loadingState.userList && !usersList.length"
          >
          {{ $t('pages.user_details.no_data_found') }}
          </div>
          <div v-else class="py-16"></div>
        </template>
      </vue-good-table>
      <button
        v-if="
          selectedDropDownItems && Object.keys(selectedDropDownItems).length
        "
        class=" cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute left-2 top-2 z-50"
        @click="clearFilters"
      >
      {{ $t('actions.clear_filters') }}
      </button>
    </div>

    <AddUserModal
      :user="userData"
      ref="adduserModal"
      @handleSubmit="addUser($event)"
      :roles="roles"
      :groups="groups"
      :loadingState="loadingState"
    />
  </div>
</template>

<script>
import SubHeader from "@/components/SubHeader";
import axios from "@/axios";
import Loader from "@shared/loader";
import AddUserModal from "@shared/components/addUserModal";
import Dropdown from "@shared/components/dropdown-base";
import {
  checkColumnConfig,
  prePopulatedDropdownItems,
} from "@shared/utils/functions";
import { checkPermission } from "@shared/utils/functions";

export default {
  name: "UserList",
  components: {
    SubHeader,
    Loader,
    AddUserModal,
    Dropdown,
  },
  created() {
    return this.$route.path;
  },
  data() {
    return {
      columns: [
        {
          label: "Username",
          field: "user_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "username",
          },
        },
        {
          label: "Email",
          field: "email",
          filterOptions: {
            enabled: true, // enable filter for this column
            multi: true,
            isLoading: false,
          },
        },
        {
          label: "Users position",
          field: "position",
          filterOptions: {
            enabled: false, // disable filter for this column
          },
        },
        {
          label: "Telephone",
          field: "mobile",
          filterOptions: {
            enabled: false, // disable filter for this column
          },
        },
        {
          label: "Role",
          field: "role_ids",
          sortable: false,
          filterOptions: {
            enabled: true, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "role",
          },
        },
        {
          label: "Group",
          field: "group_ids",
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "group",
          },
        },
      ],
      roles: [],
      groups: [],
      tenant_id: "",
      loadingState: {
        userList: false,
        roles: false,
        groups: false,
      },
      usersList: [],
      searchTimeout: 500,
      selectedDropDownItems: null,
      searchParams: null,
      userData: {
        username: "",
        email: "",
        roles: [],
        groups: [],
        mobile: "",
        position: "",
      },
      pageData: null,
      selectedOptions: {},
      perPage: 10,
    };
  },
  computed: {
    // getColumns() {
    //     if (this.groups.length) {
    //         console.log("ddsdsd")
    //         return prePopulatedDropdownItems(
    //             this.columns.map((el) => {
    //                 if (el.field === "group_ids" || el.field === "role_ids") {
    //                     return { ...el, filterOptions: { ...el.filterOptions, customDropdownItems: el.field === "group_ids" ? this.groups?.map(el => ({id:el.id, name:el.title})) : this.roles?.map(el => ({id:el.id, name:el.title})) } };
    //                 } else {
    //                     return el;
    //                 }
    //             }),
    //             this.usersList
    //         );
    //     } else {
    //         return prePopulatedDropdownItems(this.columns, this.usersList);
    //     }
    // },
    
    columnsList() {
        return this.columns.map((col) => ({
            ...col,
            label: this.$t(`pages.user_details.${col.field}`),
        }))
    },
    getRows() {
      return this.usersList;
    },
  },
  async mounted() {
    this.tenant_id = this.$store.getters.getTenantId;
    this.usersList = await this.getUsersLists();
    await this.fetchRoles();
    await this.fetchGroups();
    this.columns = prePopulatedDropdownItems(
      this.columns.map((el) => {
        if (el.field === "group_ids" || el.field === "role_ids") {
          return {
            ...el,
            filterOptions: {
              ...el.filterOptions,
              customDropdownItems:
                el.field === "group_ids"
                  ? this.groups?.map((el) => ({ id: el.id, name: el.title }))
                  : this.roles?.map((el) => ({ id: el.id, name: el.title })),
            },
          };
        } else {
          return el;
        }
      }),
      this.usersList
    );
  },
  methods: {
    checkPermission,
    async addUser(userData) {
      userData.username = userData.username.trim();
      if (userData.username.length === 0) {
        return;
      }
      const roleIds = userData?.roles.map((x) => ({
        role_id: userData?.roles.find((y) => y.id === x.id).id,
      }));
      const groupIds = userData?.groups.map((x) => ({
        group_id: userData?.groups.find((y) => y.id === x.id).id,
      }));
      let payload = {
        email: userData.email.trim(),
        user_name: userData.username,
        group_ids: groupIds,
        role_ids: roleIds,
        mobile: userData.mobile,
        position: userData.position,
      };
      try {
        let { data } = await axios.post("user/tenant", payload);
        // this.usersList = await this.getUsersLists();
        this.$toast.success(data.message || this.$t('pages.user_details.success_user_create'));
        this.usersList.push({
          ...payload,
          id: data.user_id,
          group_ids: userData?.groups.map((group) => ({
            group_id: group.id,
            title: group.title,
          })),
          role_ids: userData?.roles.map((role) => ({
            group_id: role.id,
            title: role.title,
          })),
        });
        this.$refs["adduserModal"].closeModal();
      } catch (error) {
        this.$toast.error(error.response.data.detail || this.$t('pages.user_details.error_user_add'));
      }
    },
    async handleShowModal() {
      await this.$store.dispatch("fetchPermissions");
      if (!this.checkPermission("users.create")) {
        this.$toast.error(this.$t('pages.user_details.you_dont_have_permission_to_create_users'));
        return;
      }
      this.selectedField = null;
      this.userData = {
        username: "",
        email: "",
        roles: [],
        groups: [],
        mobile: "",
        position: "",
      };
      this.$refs["adduserModal"].showUserModal();
      await this.fetchRoles();
      await this.fetchGroups();
    },
    async getUsersLists() {
      this.loadingState.userList = true;
      let url = `user/tenant/${this.tenant_id}`;
      let items = null;
      try {
        let { data } = await axios.get(url, {
          params: { ...this.searchParams },
        });
        items = data;
      } catch (error) {
        console.log(error, "<<<<error while fetching users");
      }
      this.loadingState.userList = false;
      return items;
    },
    async fetchRoles() {
      this.loadingState.roles = true;
      try {
        let { data } = await axios.get(`role/tenant/${this.tenant_id}`);
        this.roles = data;
      } catch (error) {
        console.log(error, ">>>>error");
      }
      this.loadingState.roles = false;
    },
    async fetchGroups() {
      this.loadingState.groups = true;
      try {
        let { data } = await axios.get(`group/tenant/${this.tenant_id}`);
        this.groups = data;
      } catch (error) {
        console.log(error, ">>>>error");
      }
      this.loadingState.groups = false;
    },
    checkColumnConfig,
    async updateColumnFilters(column, value /* , callback */) {
      if (this.selectedOptions[column.label]) {
        this.selectedOptions = {
          ...this.selectedOptions,
          [column.label]: [...value],
        };
      } else
        this.selectedOptions = {
          ...this.selectedOptions,
          [column.label]: [...value],
        };

      let selected = {
        key: column.filterOptions.query_key || column.field,
        value: column.filterOptions.multi
          ? value.map((el) => el.name)
          : [value?.name],
      };
      if (!selected.value?.length) {
        delete this.selectedDropDownItems[selected.key];
        return (this.usersList = await this.getUsersLists());
      }

      this.selectedDropDownItems = {
        ...this.selectedDropDownItems,
        [selected.key]: selected.value,
      };
      let newLists = await this.filterListDropdownItem();
      this.usersList = newLists;
    },
    getSelectedOptions(column) {
      return this.selectedOptions[column.label];
    },
    async filterListDropdownItem(params) {
      this.loadingState.userList = true;
      let url = `user/tenant/users`;
      let items = null;
      try {
        let body = {
          tenant_id: this.tenant_id,
          ...this.selectedDropDownItems,
          ...params,
        };
        let { data } = await axios.post(url, body);
        items = data.data;
        this.pageData = data?.page_data;
      } catch (error) {
        console.log(error, "<<<<error while fetching users");
      }
      this.loadingState.userList = false;
      return items;
    },
    searchFilter(column, event) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      const delay = 500;
      this.searchTimeout = setTimeout(() => {
        this.getSearchOptions(column, event);
      }, delay);
    },
    async getSearchOptions(column, event) {
      let search = {
        key: column.filterOptions.query_key || column.field,
        value: event,
      };
      this.searchParams = { ...this.searchParams, [search.key]: search.value };
      let filteredDrowDowns = await this.getUsersLists();
      this.columns = prePopulatedDropdownItems(this.columns, filteredDrowDowns);
    },
    async onSortChange(data) {
      if (data && data.length && data[0].type !== "none") {
        data = data[0];
        let sortParams = {
          sort: data.field + ":" + data.type,
        };
        await this.filterListDropdownItem(sortParams);
      } else {
        await this.filterListDropdownItem();
      }
    },
    async onPageChange() {
      // await this.$nextTick();
      const scrollTarget = document.getElementById("userList");
      if (scrollTarget) {
        scrollTarget.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
    async clearFilters() {
      this.selectedOptions = {};
      this.searchParams = {};
      this.pageData = null;
      this.selectedDropDownItems = null;
      this.usersList = await this.getUsersLists();
    },
    onPerPageChange(e) {
      this.perPage = e.currentPerPage;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
.filter-dropdown {
  @apply border-transparent bg-white;
}
</style>

<!-- <template src="./user-table.html"></template>
<script src="./user-table.js"></script>
<style src="./user-table.scss" scoped lang="scss"></style> -->
