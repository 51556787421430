<template>
  <div
    class="-m-2 wide:-m-4 p-2 wide:p-4 text-base-content"
    style="height: calc(100% + 32px); width: calc(100% + 32px)"
  >
    <Tabs @select="selectTab" :selected="tabs.selected" :tabs="tabs.list">
      <template #content-userList>
        <user-list />
      </template>
      <template #content-pendingApprovals>
        <pending-approvals />
      </template>
    </Tabs>
  </div>
</template>

<script>
import Tabs from "@/components/dashboard-components/tabs";
import UserList from "./UserList.vue";
import PendingApprovals from "./PendingApprovals.vue";
import axios from "@/axios";
import { EventBus } from "@/main.js";


export default {
  name: "UserDetail",
  components: {
    Tabs,
    UserList,
    PendingApprovals,
  },
  data() {
    return {
      tabs: {
        selected: "userList",
        list: [
          {
            id: "userList",
            label:  this.$t('pages.user_details.user_list'),
          },
          {
            id: "pendingApprovals",
            label: this.$t('pages.user_details.pending_approval'),
            showCount: true,
            count: 0,
          },
        ],
      },
      pendingApprovalCount: 0,
    };
  },
  mounted() {
    this.tabs.selected = this.$route?.query?.tab || "userList";
    this.fetchPendingApprovalList();
    EventBus.$on("updateCountForPendingApproval", (pendingApprovalCount) => {
        this.tabs.list.forEach((el) => {
            if(el.id === 'pendingApprovals') {
              el.count = pendingApprovalCount;
            }
          })
      })
  },
  computed: {
    tabsList() {
      return [
        {
          id: "userList",
          label: this.$t('pages.user_details.user_list'),
        },
        {
          id: "pendingApprovals",
          label: this.$t('pages.user_details.pending_approval'),
          showCount: true,
          count: 0,
        },
      ]
    }
  },
  methods: {
    selectTab(id) {
      this.tabs.selected = id;
      this.$router.push({
        path: this.$route.path,
        query: {
          tab: this.tabs.selected,
        },
      });
    },
    async fetchPendingApprovalList() {
        let url = `user/tenant/pending`;
        try {
          const response = await axios.get(url);
          let pendingApprovalCount = response.data.filter((el) => el.status === 'PENDING').length;
          console.log("pendingApprovalCount", pendingApprovalCount);
          this.tabs.list.forEach((el) => {
            if(el.id === 'pendingApprovals') {
              el.count = pendingApprovalCount;
            }
          })
        } catch (error) {
          console.log("err", error);
          // this.$toast.error(
          //   error?.response?.data?.detail || "Something went wrong"
          // );
        }
        // this.loadingState.pendingApproval = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
